import { createGlobalStyle } from "styled-components"
import bodyFont from "../assets/fonts/Raleway-VariableFont_wght.ttf"

const Typography = createGlobalStyle`
    @font-face {
        font-family: Raleway;
        src: url(${bodyFont});
        font-weight: 100 1000;
        font-display: fallback;
    }

    html {
        font-size: 62.5%;
        font-family: Raleway;
    }

    body {
        font-size: 1.8rem;
    }

    h1, .h1 {
        font-size: clamp(4rem, 4vw, 6.4rem);
        font-weight: 700;
    }

    h2, .h2 {
        font-size: clamp(2.6rem, 4vw, 3.8rem);
        line-height: 1.1;
        padding: 2rem 0 0.6rem;
    }

    h3, .h3 {
        font-size: clamp(2rem, 4vw, 3rem);
        padding: 2rem 0 0.6rem;
    }

    h4, .h4 {
        font-size: clamp(1.8rem, 4vw, 2.2rem);
        padding: 10px 0;
    }

    section > h1 {
        padding-bottom: 2rem;
    }

    h1 + p {
        border-bottom: 3px dotted var(--dkBlue);
        margin-bottom: 20px;
        padding-top: 6px;
        font-weight: 400;
    }

    h2 + p {
        padding-top: 0;
    }

    a {
        color: var(--blue);
        display: inline-block;
        border-width: 0;
        font-weight: 500;

        &:hover,
        &:focus {
            text-decoration: underline;
            color: var(--dkBlue);
        }
    }

    ul {
        padding: 20px 0;

        li {
            line-height: 1.5;

            + li {
                margin-top: 1.2rem;
            }
        }
    }

    p {
        padding: 10px 0;
        line-height: 1.5;
    }
`

export default Typography
