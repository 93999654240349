import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

import Logo from './Logo';

interface FooterProps {
    
}

const FooterStyles = styled.footer`
    background-color: var(--blue);
    color: var(--white);
    font-size: 0.8rem;
    padding: 1.6rem 0;

    ul {
        font-size: 1.4rem;

        li {
            margin-bottom: 4px;
        }

        a {
            color: var(--white);
        }
    }
`

export const Footer = (props: FooterProps) => {
    return (
        <FooterStyles>
            <div className="container container-flex">
                <Logo />
                <ul>
                    <li>
                        <Link to="guiding-principles">Guiding Principles</Link>
                    </li>
                </ul>
            </div>
        </FooterStyles>
    )
}

export default Footer;
