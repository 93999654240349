import React from "react"
import Highlight, { defaultProps, Language } from "prism-react-renderer"
import styled from "styled-components"

interface SyntaxHighlighterProps {
  children: {
    props: {
      children: {
        trim: Function
      }
      className: String
    }
  }
}

const SyntaxStyles = styled.div`
  position: relative;
  margin-bottom: 10px;

  .lang-flag {
    position: absolute;
    top: 0;
    left: 1rem;
    padding: 0.5rem;
    background-color: var(--blue);
    color: var(--white);
  }

  .token-line {
    padding: .2rem 0;
    white-space: break-spaces;
  }

  pre {
    padding: 5rem 1rem 1rem;
    border-radius: 4px;
    margin: 0;
  }

  + h3 {
    margin-top: 40px;
  }
`

const SyntaxHighlighter = (props: SyntaxHighlighterProps) => {
  // Grab the classname and extract the language from it
  const className = props.children.props.className || ""
  const matches = className.match(/language-(?<lang>.*)/)
  const language =
    matches && matches.groups && matches.groups.lang
      ? (matches.groups.lang as Language)
      : null

  return (
    <SyntaxStyles>
      <Highlight
        {...defaultProps}
        code={props.children.props.children.trim()}
        language={language}
      >
        {({ className, style, tokens, getLineProps, getTokenProps }) => (
          <>
            {language && <span className="lang-flag">{language}</span>}
            <pre className={className} style={style}>
              {tokens.map((line, i) => (
                <div {...getLineProps({ line, key: i })}>
                  {line.map((token, key) => (
                    <span {...getTokenProps({ token, key })} />
                  ))}
                </div>
              ))}
            </pre>
          </>
        )}
      </Highlight>
    </SyntaxStyles>
  )
}

export default SyntaxHighlighter
