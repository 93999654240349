import React from "react"
import Header from "./Header"
import Footer from "./Footer"

import "normalize.css"
import Typography from "../styles/Typography"
import GlobalStyles from "../styles/Global"

interface Props {
  children: JSX.Element
}

const Layout = (props: Props): JSX.Element => {
  return (
    <>
      <Typography />
      <GlobalStyles />
      <Header />
      <main>{props.children}</main>
      <Footer />
    </>
  )
} 

export default Layout
