import React from "react"
import { MDXProvider } from "@mdx-js/react"
import Layout from "./src/components/Layout"
import SyntaxHighlighter from "./src/components/SyntaxHighlighter/SyntaxHighlighter"

const components = {
  pre: SyntaxHighlighter,
}

export const wrapRootElement = ({ element }) => (
  <MDXProvider components={components}>{element}</MDXProvider>
)

export const wrapPageElement = ({ element, props }) => (
  <Layout {...props}>{element}</Layout>
)
