import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';


const StyledLink = styled(props => <Link {...props} />)`
    display: inline-block;
    display: flex;
    flex-direction: column;
    text-align: center;
    color: var(--white);
    text-decoration: none;

    span:first-child {
        font-size: 4em;
        border-top: 4px solid var(--white);
        border-bottom: 4px solid var(--white);
        font-weight: 600;
        letter-spacing: 0.2rem;
        margin-bottom: .6rem;
    }

    span:last-child {
        font-weight: 500;
        font-size: 1.8em;
        letter-spacing: 0.12rem;
    }

    :hover,
    :focus {
        text-decoration: none;
        color: var(--white);
    }
`

const Logo = () => {
    return (
        <StyledLink to="/">
            <span>BSEE</span>
            <span>UI TEAM</span>
        </StyledLink>
    )
}

export default Logo;
