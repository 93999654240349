import { createGlobalStyle } from "styled-components"

const GlobalStyles = createGlobalStyle`
    :root {
        --blue: #1471da;
        --dkBlue: #3A28C7;
        --white: #fff;
        --gray:  #e5e5e5;
        --drkGray: #6D6D6D;
        --radius: 0.4rem;
    }

    html, body, #___gatsby, #gatsby-focus-wrapper {
        height: 100%;
    }

    main {
        min-height: calc(100vh - 212px);
    }

    // CUSTOM RESET

    *,
    *::before,
    *::after {
        box-sizing:border-box;
    }

    a {
        text-decoration:none; 
        cursor:pointer;
    }

    button  {
        background-color:transparent; 
        color:inherit; 
        border-width:0; 
        padding:0; 
        cursor:pointer;
    }

    figure  {
        margin:0;
    }

    input::-moz-focus-inner {
        border:0; 
        padding:0; 
        margin:0;
    }

    ul, ol, dd  {
        margin:0; 
        list-style:none;
    }

    h1, h2, h3, h4, h5, h6 {
        margin:0;
    }

    p {
        margin:0;
    }

    cite {
        font-style:normal;
    }

    fieldset {
        border-width:0; 
        padding:0; 
        margin:0;
    }

    section {
        padding: 60px 0;
    }

    .container {
        max-width: 1200px;
        padding: 0 clamp(1.6rem, 4vw, 6rem);
        margin: 0 auto;

        &-flex {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }

    .btn {
        padding: 20px 0;
        font-weight: 700;

        &-right {
            text-align: right;
            display: flow-root;
            padding-bottom: 0;
        }
    }
`

export default GlobalStyles
