import React from 'react'
import styled from 'styled-components';

import Logo from './Logo';
import Navigation from './Navigation';

interface HeaderProps {
    
}

const HeaderStyles = styled.header`
    background-color: var(--blue);
    padding: 1.2rem 0;
    font-size: 1rem;

    .container-flex {
        max-width: 1400px;
        margin: 0 auto;
        padding: 0 clamp(2rem, 3vw, 4rem);
    }
`

export const Header = (props: HeaderProps) => {
    return (
        <HeaderStyles>
            <div className="container-flex">
                <Logo />
                <Navigation />
            </div>
        </HeaderStyles>
    )
}

export default Header;
